import api from '@/api';

export const state = () => ({
  autoComplete: [],
  universities: [],
  university_courses: [],
  resultList: [],
  english_courses: [],
  institutes: [],
  countries: [],
});

export const actions = {
  getAutoComplete({ state }, payload) {
    api.get(`/auto-complete`).then(res => {
      state.universities = res.data.universities;
      state.university_courses = res.data.university_courses;
      state.english_courses = res.data.english_courses;
      state.institutes = res.data.institutes;
      state.countries = res.data.countries;
      if (payload === 'universities') {
        state.autoComplete = [
          ...state.universities.map(item => ({
            id: item.id,
            label: item.name,
            query: `university_id${item.id}`,
          })),
        ];
      } else if (payload === 'institutes') {
        state.autoComplete = [...state.institutes.map(item => ({ id: item.id, label: item.name }))];
      }
    });
  },
  getAutoCompleteUniversity({ state }, payload) {
    api.get(`/auto-complete${payload.query}`).then(res => {
      state.universities = res.data.universities;
      state.university_courses = res.data.university_courses;
      state.english_courses = res.data.english_courses;
      state.institutes = res.data.institutes;
      state.countries = res.data.countries;
      if (payload.type === 'universities') {
        state.autoCompleteUniversities = [
          ...state.university_courses.map(item => ({
            id: item.id,
            label: item.name,
            query: `university_id${item.id}`,
          })),
        ];
        state.resultList = [
          ...state.university_courses.map(item => ({
            id: item.id,
            name: item.name,
            offer: item.fees,
            query: `&university_course_ids[]=${item.id}`,
          })),
        ];
      } else if (payload.type === 'institutes') {
        state.autoCompleteUniversities = [
          ...state.english_courses.map(item => ({
            id: item.id,
            label: item.name,
          })),
        ];
        state.resultList = [
          ...state.english_courses.map(item => ({
            id: item.id,
            name: item.name,
            offer: item.fees,
            query: `&english_course_ids[]=${item.id}`,
          })),
        ];
      }
    });
  },
};

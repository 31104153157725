import {
  applicationsVuexStore,
  autoCompleteVuexStore,
  branchVuexStore,
  citiesVuexStore,
  citiesListVuexStore,
  compareVuexStore,
  countriesVuexStore,
  countriesListVuexStore,
  courseDetailsVuexStore,
  coursesListVuexStore,
  datesVuexStore,
  degreeTypeVuexStore,
  destinationsVuexStore,
  documentVuexStore,
  educationalLevelVuexStore,
  englishCoursesVuexStore,
  favoritesVuexStore,
  fruitsVuexStore,
  functionsVuexStore,
  instituteDetailsVuexStore,
  institutesVuexStore,
  levelsVuexStore,
  loginVuexStore,
  notificationVuexStore,
  profileVuexStore,
  profileImgVuexStore,
  registerVuexStore,
  searchVuexStore,
  studentVuexStore,
  subjectsVuexStore,
  universitiesVuexStore,
  universitiesDetailsVuexStore,
  universityCoursesVuexStore 
} from "#imports"
const VuexStore = {
  modules: {
      applications: {
        ...applicationsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      autoComplete: {
        ...autoCompleteVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      branch: {
        ...branchVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      cities: {
        ...citiesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      citiesList: {
        ...citiesListVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      compare: {
        ...compareVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      countries: {
        ...countriesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      countriesList: {
        ...countriesListVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      courseDetails: {
        ...courseDetailsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      coursesList: {
        ...coursesListVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      dates: {
        ...datesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      degreeType: {
        ...degreeTypeVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      destinations: {
        ...destinationsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      document: {
        ...documentVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      educationalLevel: {
        ...educationalLevelVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      englishCourses: {
        ...englishCoursesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      favorites: {
        ...favoritesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      fruits: {
        ...fruitsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      functions: {
        ...functionsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      instituteDetails: {
        ...instituteDetailsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      institutes: {
        ...institutesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      levels: {
        ...levelsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      login: {
        ...loginVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      notification: {
        ...notificationVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      profile: {
        ...profileVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      profileImg: {
        ...profileImgVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      register: {
        ...registerVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      search: {
        ...searchVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      student: {
        ...studentVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      subjects: {
        ...subjectsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      universities: {
        ...universitiesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      universitiesDetails: {
        ...universitiesDetailsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      universityCourses: {
        ...universityCoursesVuexStore,
        namespaced: true,
        modules: {},
      }
    }, 
  }
export default VuexStore
import api from '@/api';
export const state = () => ({
  token: [],
});

export const actions = {
  login({ state }, payload) {
    api
      .post('/auth/register', {
        email: payload.email,
        password: payload.password,
      })
      .then(res => {
        state.countries = res.data.data;
        localStorage.setItem('userlogin', true);
        if (localStorage.getItem('APP_LANG') === 'ar') {
          this.$router.push('/ar/');
        } else {
          this.$router.push('/');
        }
      });
  },
};

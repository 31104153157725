export const state = () => ({
  countries: [],
});

export const actions = {
  getCountries({ state }) {
    const { $axios } = useNuxtApp();
    $axios.get('/countries').then(res => {
      state.countries = res.data.data;
    });
  },
};

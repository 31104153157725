import api from '@/api';
export const state = () => ({
  applicationList: [],
  seMore: 9,
  loading: true,
});

export const actions = {
  getApplication({ state }) {
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    state.loading = true;
    api
      .get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/applications?per_page=${state.seMore}`)
      .then(res => {
        state.applicationList = res.data.data;
        state.loading = false;
      });
  },
};

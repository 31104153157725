export const state = () => ({
  name: 'fruits',
});

export const actions = {
  addFruit(context, fruit) {
    const slicedFruit = sliceFruit(fruit);
    context.commit(slicedFruit);
  },
};

import { default as application_45transfervoBiN2UaNcMeta } from "/root/project/frontend/pages/application-transfer.vue?macro=true";
import { default as indexUcsDRG2NV6Meta } from "/root/project/frontend/pages/applications/index.vue?macro=true";
import { default as indexfsmp7dMf6mMeta } from "/root/project/frontend/pages/apply/index.vue?macro=true";
import { default as institutebuaPJAqjdbMeta } from "/root/project/frontend/pages/apply/institute.vue?macro=true";
import { default as reviewuzr4Glw85GMeta } from "/root/project/frontend/pages/apply/review.vue?macro=true";
import { default as forgot_45passwordq1VxpZTkhfMeta } from "/root/project/frontend/pages/auth/forgot-password.vue?macro=true";
import { default as loginEHGnXXsj1QMeta } from "/root/project/frontend/pages/auth/login.vue?macro=true";
import { default as new_45passwordhB0l3MAfJ5Meta } from "/root/project/frontend/pages/auth/new-password.vue?macro=true";
import { default as indexQfGk1a4ThlMeta } from "/root/project/frontend/pages/book-appointment/index.vue?macro=true";
import { default as indexWibbO0v1maMeta } from "/root/project/frontend/pages/commission/index.vue?macro=true";
import { default as indexmVIChce8ZQMeta } from "/root/project/frontend/pages/course/[id]/index.vue?macro=true";
import { default as indexTbawHobD6OMeta } from "/root/project/frontend/pages/destinations/index.vue?macro=true";
import { default as indexjpAUGJWQ8LMeta } from "/root/project/frontend/pages/index.vue?macro=true";
import { default as _91id_93PcI60TSsuLMeta } from "/root/project/frontend/pages/institute/[id].vue?macro=true";
import { default as _91id_93tjde5Xvma4Meta } from "/root/project/frontend/pages/institutes/[id].vue?macro=true";
import { default as _91id_930VWs39PLtBMeta } from "/root/project/frontend/pages/institutes/course/[id].vue?macro=true";
import { default as _91id_93ATMGIZuNFMMeta } from "/root/project/frontend/pages/leads/[id].vue?macro=true";
import { default as index9lqZO8tMsxMeta } from "/root/project/frontend/pages/leads/index.vue?macro=true";
import { default as indexTixsvvDUN8Meta } from "/root/project/frontend/pages/privacy-and-policy/index.vue?macro=true";
import { default as compareYlVXGwYpQ9Meta } from "/root/project/frontend/pages/profile/compare.vue?macro=true";
import { default as favoritesAC1LcgqweJMeta } from "/root/project/frontend/pages/profile/favorites.vue?macro=true";
import { default as indexm98AyiWbr1Meta } from "/root/project/frontend/pages/profile/index.vue?macro=true";
import { default as notificationskbftiFLkKLMeta } from "/root/project/frontend/pages/profile/notifications.vue?macro=true";
import { default as profilesD2QDji6aeMeta } from "/root/project/frontend/pages/profile.vue?macro=true";
import { default as _91category_93_45coursefXH8q9MlXwMeta } from "/root/project/frontend/pages/search/[category]-course.vue?macro=true";
import { default as _91category_93CyBMJO5JOlMeta } from "/root/project/frontend/pages/search/[category].vue?macro=true";
import { default as indexf5CwVgdUpHMeta } from "/root/project/frontend/pages/team-members/index.vue?macro=true";
import { default as indexgG9SYcORCAMeta } from "/root/project/frontend/pages/team-members/member/index.vue?macro=true";
import { default as indexzD5l8ewkA2Meta } from "/root/project/frontend/pages/terms-and-conditions/index.vue?macro=true";
import { default as indexKCWmRnzVU8Meta } from "/root/project/frontend/pages/thank-you/index.vue?macro=true";
import { default as _91id_93ElVkT0thiVMeta } from "/root/project/frontend/pages/university/[id].vue?macro=true";
import { default as _91id_93nuQqZ0uvzqMeta } from "/root/project/frontend/pages/university/course/[id].vue?macro=true";
export default [
  {
    name: "application-transfer___en",
    path: "/application-transfer",
    component: () => import("/root/project/frontend/pages/application-transfer.vue")
  },
  {
    name: "application-transfer___ar",
    path: "/ar/application-transfer",
    component: () => import("/root/project/frontend/pages/application-transfer.vue")
  },
  {
    name: "applications___en",
    path: "/applications",
    component: () => import("/root/project/frontend/pages/applications/index.vue")
  },
  {
    name: "applications___ar",
    path: "/ar/applications",
    component: () => import("/root/project/frontend/pages/applications/index.vue")
  },
  {
    name: "apply___en",
    path: "/apply",
    component: () => import("/root/project/frontend/pages/apply/index.vue")
  },
  {
    name: "apply___ar",
    path: "/ar/apply",
    component: () => import("/root/project/frontend/pages/apply/index.vue")
  },
  {
    name: "apply-institute___en",
    path: "/apply/institute",
    component: () => import("/root/project/frontend/pages/apply/institute.vue")
  },
  {
    name: "apply-institute___ar",
    path: "/ar/apply/institute",
    component: () => import("/root/project/frontend/pages/apply/institute.vue")
  },
  {
    name: "apply-review___en",
    path: "/apply/review",
    component: () => import("/root/project/frontend/pages/apply/review.vue")
  },
  {
    name: "apply-review___ar",
    path: "/ar/apply/review",
    component: () => import("/root/project/frontend/pages/apply/review.vue")
  },
  {
    name: "auth-forgot-password___en",
    path: "/auth/forgot-password",
    meta: forgot_45passwordq1VxpZTkhfMeta || {},
    component: () => import("/root/project/frontend/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-forgot-password___ar",
    path: "/ar/auth/forgot-password",
    meta: forgot_45passwordq1VxpZTkhfMeta || {},
    component: () => import("/root/project/frontend/pages/auth/forgot-password.vue")
  },
  {
    name: "auth-login___en",
    path: "/auth/login",
    meta: loginEHGnXXsj1QMeta || {},
    component: () => import("/root/project/frontend/pages/auth/login.vue")
  },
  {
    name: "auth-login___ar",
    path: "/ar/auth/login",
    meta: loginEHGnXXsj1QMeta || {},
    component: () => import("/root/project/frontend/pages/auth/login.vue")
  },
  {
    name: "auth-new-password___en",
    path: "/auth/new-password",
    meta: new_45passwordhB0l3MAfJ5Meta || {},
    component: () => import("/root/project/frontend/pages/auth/new-password.vue")
  },
  {
    name: "auth-new-password___ar",
    path: "/ar/auth/new-password",
    meta: new_45passwordhB0l3MAfJ5Meta || {},
    component: () => import("/root/project/frontend/pages/auth/new-password.vue")
  },
  {
    name: "book-appointment___en",
    path: "/book-appointment",
    component: () => import("/root/project/frontend/pages/book-appointment/index.vue")
  },
  {
    name: "book-appointment___ar",
    path: "/ar/book-appointment",
    component: () => import("/root/project/frontend/pages/book-appointment/index.vue")
  },
  {
    name: "commission___en",
    path: "/commission",
    component: () => import("/root/project/frontend/pages/commission/index.vue")
  },
  {
    name: "commission___ar",
    path: "/ar/commission",
    component: () => import("/root/project/frontend/pages/commission/index.vue")
  },
  {
    name: "course-id___en",
    path: "/course/:id()",
    component: () => import("/root/project/frontend/pages/course/[id]/index.vue")
  },
  {
    name: "course-id___ar",
    path: "/ar/course/:id()",
    component: () => import("/root/project/frontend/pages/course/[id]/index.vue")
  },
  {
    name: "destinations___en",
    path: "/destinations",
    meta: indexTbawHobD6OMeta || {},
    component: () => import("/root/project/frontend/pages/destinations/index.vue")
  },
  {
    name: "destinations___ar",
    path: "/ar/destinations",
    meta: indexTbawHobD6OMeta || {},
    component: () => import("/root/project/frontend/pages/destinations/index.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/root/project/frontend/pages/index.vue")
  },
  {
    name: "index___ar",
    path: "/ar",
    component: () => import("/root/project/frontend/pages/index.vue")
  },
  {
    name: "institute-id___en",
    path: "/institute/:id()",
    meta: _91id_93PcI60TSsuLMeta || {},
    component: () => import("/root/project/frontend/pages/institute/[id].vue")
  },
  {
    name: "institute-id___ar",
    path: "/ar/institute/:id()",
    meta: _91id_93PcI60TSsuLMeta || {},
    component: () => import("/root/project/frontend/pages/institute/[id].vue")
  },
  {
    name: "institutes-id___en",
    path: "/institutes/:id()",
    meta: _91id_93tjde5Xvma4Meta || {},
    component: () => import("/root/project/frontend/pages/institutes/[id].vue")
  },
  {
    name: "institutes-id___ar",
    path: "/ar/institutes/:id()",
    meta: _91id_93tjde5Xvma4Meta || {},
    component: () => import("/root/project/frontend/pages/institutes/[id].vue")
  },
  {
    name: "institutes-course-id___en",
    path: "/institutes/course/:id()",
    meta: _91id_930VWs39PLtBMeta || {},
    component: () => import("/root/project/frontend/pages/institutes/course/[id].vue")
  },
  {
    name: "institutes-course-id___ar",
    path: "/ar/institutes/course/:id()",
    meta: _91id_930VWs39PLtBMeta || {},
    component: () => import("/root/project/frontend/pages/institutes/course/[id].vue")
  },
  {
    name: "leads-id___en",
    path: "/leads/:id()",
    component: () => import("/root/project/frontend/pages/leads/[id].vue")
  },
  {
    name: "leads-id___ar",
    path: "/ar/leads/:id()",
    component: () => import("/root/project/frontend/pages/leads/[id].vue")
  },
  {
    name: "leads___en",
    path: "/leads",
    component: () => import("/root/project/frontend/pages/leads/index.vue")
  },
  {
    name: "leads___ar",
    path: "/ar/leads",
    component: () => import("/root/project/frontend/pages/leads/index.vue")
  },
  {
    name: "privacy-and-policy___en",
    path: "/privacy-and-policy",
    component: () => import("/root/project/frontend/pages/privacy-and-policy/index.vue")
  },
  {
    name: "privacy-and-policy___ar",
    path: "/ar/privacy-and-policy",
    component: () => import("/root/project/frontend/pages/privacy-and-policy/index.vue")
  },
  {
    name: profilesD2QDji6aeMeta?.name,
    path: "/profile",
    component: () => import("/root/project/frontend/pages/profile.vue"),
    children: [
  {
    name: "profile-compare___en",
    path: "compare",
    component: () => import("/root/project/frontend/pages/profile/compare.vue")
  },
  {
    name: "profile-favorites___en",
    path: "favorites",
    component: () => import("/root/project/frontend/pages/profile/favorites.vue")
  },
  {
    name: "profile___en",
    path: "",
    component: () => import("/root/project/frontend/pages/profile/index.vue")
  },
  {
    name: "profile-notifications___en",
    path: "notifications",
    component: () => import("/root/project/frontend/pages/profile/notifications.vue")
  }
]
  },
  {
    name: profilesD2QDji6aeMeta?.name,
    path: "/ar/profile",
    component: () => import("/root/project/frontend/pages/profile.vue"),
    children: [
  {
    name: "profile-compare___ar",
    path: "compare",
    component: () => import("/root/project/frontend/pages/profile/compare.vue")
  },
  {
    name: "profile-favorites___ar",
    path: "favorites",
    component: () => import("/root/project/frontend/pages/profile/favorites.vue")
  },
  {
    name: "profile___ar",
    path: "",
    component: () => import("/root/project/frontend/pages/profile/index.vue")
  },
  {
    name: "profile-notifications___ar",
    path: "notifications",
    component: () => import("/root/project/frontend/pages/profile/notifications.vue")
  }
]
  },
  {
    name: "search-category-course___en",
    path: "/search/:category()-course",
    meta: _91category_93_45coursefXH8q9MlXwMeta || {},
    component: () => import("/root/project/frontend/pages/search/[category]-course.vue")
  },
  {
    name: "search-category-course___ar",
    path: "/ar/search/:category()-course",
    meta: _91category_93_45coursefXH8q9MlXwMeta || {},
    component: () => import("/root/project/frontend/pages/search/[category]-course.vue")
  },
  {
    name: "search-category___en",
    path: "/search/:category()",
    meta: _91category_93CyBMJO5JOlMeta || {},
    component: () => import("/root/project/frontend/pages/search/[category].vue")
  },
  {
    name: "search-category___ar",
    path: "/ar/search/:category()",
    meta: _91category_93CyBMJO5JOlMeta || {},
    component: () => import("/root/project/frontend/pages/search/[category].vue")
  },
  {
    name: "team-members___en",
    path: "/team-members",
    component: () => import("/root/project/frontend/pages/team-members/index.vue")
  },
  {
    name: "team-members___ar",
    path: "/ar/team-members",
    component: () => import("/root/project/frontend/pages/team-members/index.vue")
  },
  {
    name: "team-members-member___en",
    path: "/team-members/member",
    component: () => import("/root/project/frontend/pages/team-members/member/index.vue")
  },
  {
    name: "team-members-member___ar",
    path: "/ar/team-members/member",
    component: () => import("/root/project/frontend/pages/team-members/member/index.vue")
  },
  {
    name: "terms-and-conditions___en",
    path: "/terms-and-conditions",
    component: () => import("/root/project/frontend/pages/terms-and-conditions/index.vue")
  },
  {
    name: "terms-and-conditions___ar",
    path: "/ar/terms-and-conditions",
    component: () => import("/root/project/frontend/pages/terms-and-conditions/index.vue")
  },
  {
    name: "thank-you___en",
    path: "/thank-you",
    component: () => import("/root/project/frontend/pages/thank-you/index.vue")
  },
  {
    name: "thank-you___ar",
    path: "/ar/thank-you",
    component: () => import("/root/project/frontend/pages/thank-you/index.vue")
  },
  {
    name: "university-id___en",
    path: "/university/:id()",
    meta: _91id_93ElVkT0thiVMeta || {},
    component: () => import("/root/project/frontend/pages/university/[id].vue")
  },
  {
    name: "university-id___ar",
    path: "/ar/university/:id()",
    meta: _91id_93ElVkT0thiVMeta || {},
    component: () => import("/root/project/frontend/pages/university/[id].vue")
  },
  {
    name: "university-course-id___en",
    path: "/university/course/:id()",
    meta: _91id_93nuQqZ0uvzqMeta || {},
    component: () => import("/root/project/frontend/pages/university/course/[id].vue")
  },
  {
    name: "university-course-id___ar",
    path: "/ar/university/course/:id()",
    meta: _91id_93nuQqZ0uvzqMeta || {},
    component: () => import("/root/project/frontend/pages/university/course/[id].vue")
  }
]
export const state = () => ({
  studentAdd: null,
});

export const actions = {
  addStudent({ state }, payload) {
    state.studentAdd = {
      email: payload.email,
      name: `${payload.first_name} ${payload.middle_name} ${payload.last_name}`,
      value: payload.id,
      phone: payload.phone,
    };
  },
};

import api from '@/api';
export const state = () => ({
  count: '',
  notificationList: [],
  notificationListBell: [],
  per_page: 9,
  loading: false,
});

export const actions = {
  getCountNumber({ state }) {
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    api.get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/notifications/count/unread`).then(res => {
      state.count = res.data.count;
    });
  },
  getNotificationsList({ state }) {
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    api
      .get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/notifications?&per_page=${state.per_page}`)
      .then(res => {
        state.notificationList = res.data.data;
      });
  },
  getNotificationsListBell({ state }) {
    state.loading = true;
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    api.get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/notifications?per_page=3`).then(res => {
      state.notificationListBell = res.data.data;
      state.loading = false;
    });
  },
};

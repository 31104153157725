export const state = () => ({
  subjects: [],
  subjectsList: [],
});

export const actions = {
  getSubjects({ state }) {
    const { $axios } = useNuxtApp();
    $axios.get(`/subjects?per_page=100`).then(res => {
      state.subjects = res.data.data;
      state.subjectsList = res.data.data.map(item => {
        return { id: item.id, name: item.name, query: `&subject_ids[]=${item.id}` };
      });
    });
  },
};

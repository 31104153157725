import api from '@/api';
export const state = () => ({
  data: [],
  favoritesList: [],
  mark: '',
  sort: '',
});

export const actions = {
  getFavorites({ state }, payload) {
    if (!payload) {
      payload = '';
    } else {
      state.mark = '?';
    }
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    api.get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/favourite${state.mark}${payload}`).then(res => {
      state.data = res.data;
      state.favoritesList = state.data = [
        ...state.data.map(item => ({
          id: item.id,
          type: item.type,
          name: item.name,
          offer: item.fees,
          offerCount: item.sale_percentage + '%',
          price: item.fees_after_sale,
          has_sale: item.has_sale,
          location: item.location,
          image: item.image,
          favourite: item.favourite,
          course_type: item.course_type,
          currency: item.currency,
        })),
      ];
    });
  },
};

import api from '@/api';
export const state = () => ({
  universityCourses: [],
  universityCoursesList: [],
  universityCoursesListDestination: [],
  getCourseDestinationList: [],
  countryNumber: 0,
  loading: false,
  result: [],
  resultCourseName: [],
  keyWord: '',
  keyWordCourse: '',
  keyWordCourseQuery: '',
  searchWord: '',
  query: '',
  order: '',
  per_page: 9,
});

export const actions = {
  getUniversityCourses({ state }) {
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    api.get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/university-courses`).then(res => {
      state.universityCourses = res.data.data;
      state.universityCoursesList = res.data.data.map(item => {
        return { id: item.id, name: item.name };
      });
    });
  },

  async getUniversityCoursesDestination({ state }) {
    let query = '';
    if (state.getCourseDestinationList.length) {
      query = `${state.getCourseDestinationList}`;
    }
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/university-courses?per_page=400&sort=1&${query}`)
      .then(res => {
        // state.universityCourses = res.data.data;
        state.universityCoursesListDestination = res.data.data.map(item => {
          return { id: item.id, name: item.name };
        });
      });
  },

  async getUniversitiesListSearch({ state }, payload) {
    if (state.keyWordCourse) {
      state.keyWordCourseQuery = `&course_name_keyword=${state.keyWordCourse}`;
    } else {
      state.keyWordCourseQuery = '';
    }
    if (payload.keyWord) {
      state.keyWord = payload.keyWord;
    } else {
      state.keyWord = '';
    }
    if (payload.query) {
      state.query = payload.query;
    } else {
      state.query = '';
    }
    state.loading = true;
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .get(
        `https://${subDomain}.${useRuntimeConfig().public.baseURL}/university-courses?university_ids[]=${state.keyWord}${state.searchWord}&per_page=${state.per_page}${state.query}&${state.order}${state.keyWordCourseQuery}`
      )
      .then(res => {
        state.loading = false;
        state.data = res.data.data;
        state.result = [
          ...state.data.map(item => ({
            id: item.id,
            type: item.type,
            name: item.name,
            offer: item.fees,
            offerCount: item.sale_percentage + '%',
            price: item.fees_after_sale,
            has_sale: item.has_sale,
            location: item.location,
            image: item.image,
            favourite: item.favourite,
            course_type: item.course_type,
            currency: item.currency,
          })),
        ];
        state.resultList = [
          ...state.data.map(item => ({
            id: item.id,
            name: item.name,
            offer: item.fees,
            query: `&university_course_ids[]=${item.id}`,
          })),
        ];
      })
      .catch(() => {
        state.loading = false;
      });
  },
  async getUniversitiesListSearchCourseName({ state }, payload) {
    if (state.keyWordCourse) {
      state.keyWordCourseQuery = `&course_name_keyword=${state.keyWordCourse}`;
    } else {
      state.keyWordCourseQuery = '';
    }
    if (payload.keyWord) {
      state.keyWord = payload.keyWord;
    } else {
      state.keyWord = '';
    }
    if (payload.query) {
      state.query = payload.query;
    } else {
      state.query = '';
    }
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .get(
        `https://${subDomain}.${useRuntimeConfig().public.baseURL}/university-courses?university_ids[]=${state.keyWord}${state.searchWord}&per_page=400${state.query}${state.keyWordCourseQuery}`
      )
      .then(res => {
        state.loading = false;
        state.data = res.data.data;
        state.resultCourseName = [
          ...state.data.map(item => ({
            id: item.id,
            name: item.name,
            offer: item.fees,
            query: `&university_course_ids[]=${item.id}`,
          })),
        ];
      })
      .catch(() => {
        state.loading = false;
      });
  },
};

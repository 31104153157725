import api from '@/api';
export const state = () => ({
  levels: [],
  levelsList: [],
  proficiencyLevel: [
    {
      id: '1',
      name: 'Beginner',
    },
    {
      id: '2',
      name: 'Early Intermediate',
    },
    {
      id: '3',
      name: 'Intermediate',
    },
    {
      id: '4',
      name: 'Advanced',
    },
    {
      id: '5',
      name: 'Fluent',
    },
  ],
});

export const actions = {
  getLevels({ state }) {
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    api.get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/levels`).then(res => {
      state.levels = res.data.data;
      state.levelsList = res.data.data.map(item => {
        return { id: item.id, name: item.name, query: `&level_ids[]=${item.id}` };
      });
    });
  },
};

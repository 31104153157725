<template>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script>
export default {
  async mounted() {
    this.$primevue.config.ripple = true;
    if (useCookie('token').value) {
      await this.$store.dispatch('countries/getCountriesRegistration');
    }
  },
};
</script>

import api from '@/api';

export const state = () => ({
  compareList: [],
  result: [],
  showDone: false,
});

export const actions = {
  async addCompare({ state }, payload) {
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .post(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/compare`, payload.dataCompare)
      .then(res => {
        state.result = res.data.data;
        if (localStorage.getItem('APP_LANG') === 'ar') {
          payload.router.push('/ar/profile/compare');
        } else {
          payload.router.push('/profile/compare');
        }
      });
  },
};

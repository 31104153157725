import api from '@/api';
export const state = () => ({
  englishCourses: [],
  englishCoursesList: [],
  englishCoursesListCity: [],
  englishCoursesListDestination: [],
  getEnglishCoursesDestinationList: [],
  loading: false,
  result: [],
  resultListCourseName: [],
  keyWord: '',
  keyWordCourse: '',
  keyWordCourseQuery: '',
  searchWord: '',
  query: '',
  order: '',
  per_page: 9,
  mark: '',
});

export const actions = {
  getEnglishCourses({ state }) {
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    api.get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/english-courses`).then(res => {
      state.englishCourses = res.data.data;
      state.englishCoursesList = res.data.data.map(item => {
        return { id: item.id, name: item.name };
      });
    });
  },
  async getEnglishCoursesDestination({ state }) {
    let query = '';
    if (state.getEnglishCoursesDestinationList.length) {
      query = `${state.getEnglishCoursesDestinationList}`;
    }
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/english-courses?per_page=400&sort=1&${query}`)
      .then(res => {
        state.englishCoursesListDestination = res.data.data.map(item => {
          return { id: item.id, name: item.name };
        });
      });
  },

  // async getEnglishCoursesSearch({ state }, payload) {
  //   if (payload.keyWord) {
  //     state.keyWord = payload.keyWord;
  //   } else {
  //     state.keyWord = ""
  //   }
  //   if (payload.query) {
  //     state.query = payload.query;
  //   } else {
  //     state.query = ""
  //   }
  //   state.loading = true
  //   const subDomain = useCookie("subagent_userInfo").value.subDomain;
  // await api.get(`/english-courses?${state.keyWord}&per_page=${state.per_page}${state.query}&${state.order}`).then((res) => {
  //     state.loading = false
  //     state.data = res.data.data;
  //     state.result = [
  //       ...state.data.map(item => ({
  //         id: item.id,
  //         type: item.type,
  //         name: item.name,
  //         offer: item.fees,
  //         offerCount: item.sale_percentage + "%",
  //         price: item.fees_after_sale,
  //         location: item.country,
  //         image: item.image,
  //         favourite: item.favourite,
  //         course_type: item.course_type,
  //         currency: item.currency
  //       })),
  //     ];
  //   });
  // },

  async getEnglishCoursesSearch({ state }, payload) {
    if (state.keyWordCourse) {
      state.keyWordCourseQuery = `&course_name_keyword=${state.keyWordCourse}`;
    } else {
      state.keyWordCourseQuery = '';
    }
    if (payload.keyWord) {
      state.keyWord = payload.keyWord;
    } else {
      state.keyWord = '';
    }
    if (payload.query) {
      state.query = payload.query;
    } else {
      state.query = '';
    }
    state.loading = true;
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .get(
        `https://${subDomain}.${useRuntimeConfig().public.baseURL}/english-courses?institute_ids[]=${state.keyWord}${state.searchWord}&per_page=400${state.query}&${state.order}${state.keyWordCourseQuery}`
      )
      .then(res => {
        state.loading = false;
        state.data = res.data.data;
        state.result = [
          ...state.data.map(item => ({
            id: item.id,
            type: item.type,
            name: item.name,
            offer: item.fees,
            offerCount: item.sale_percentage + '%',
            price: item.fees_after_sale,
            has_sale: item.has_sale,
            location: item.location,
            image: item.image,
            favourite: item.favourite,
            course_type: item.course_type,
            currency: item.currency,
          })),
        ];
        state.resultList = [
          ...state.data.map(item => ({
            id: item.id,
            name: item.name,
            offer: item.fees,
            query: `&english_course_ids[]=${item.id}`,
          })),
        ];
      })
      .catch(() => {
        state.loading = false;
      });
  },
  async getEnglishCoursesSearchCourseName({ state }, payload) {
    if (state.keyWordCourse) {
      state.keyWordCourseQuery = `&course_name_keyword=${state.keyWordCourse}`;
    } else {
      state.keyWordCourseQuery = '';
    }
    if (payload.keyWord) {
      state.keyWord = payload.keyWord;
    } else {
      state.keyWord = '';
    }
    if (payload.query) {
      state.query = payload.query;
    } else {
      state.query = '';
    }
    // state.loading = true
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .get(
        `https://${subDomain}.${useRuntimeConfig().public.baseURL}/english-courses?institute_ids[]=${state.keyWord}${state.searchWord}&per_page=${state.per_page}${state.query}&${state.order}${state.keyWordCourseQuery}`
      )
      .then(res => {
        state.loading = false;
        state.data = res.data.data;
        state.resultListCourseName = [
          ...state.data.map(item => ({
            id: item.id,
            name: item.name,
            offer: item.fees,
            query: `&english_course_ids[]=${item.id}`,
          })),
        ];
      })
      .catch(() => {
        state.loading = false;
      });
  },
};

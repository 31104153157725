import api from '@/api';

export const state = () => ({
  mapList: [],
  destinationsList: [],
  destinationsListUniversity: [],
  destinationsListEnglish: [],
  result: [],
  loading: false,
});

export const actions = {
  async getDestinations({ state }) {
    state.loading = false;
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    api.get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/countries?is_map=1`).then(res => {
      state.mapList = res.data.data.map(item => {
        return {
          id: item.id,
          name: item.name,
          code: item.code,
          image: item.image,
          query: `&country_ids[]=${item.id}`,
        };
      });
      const listUniversity = res.data.data.filter(item => item.is_university);
      state.destinationsListUniversity = listUniversity.map(item => {
        return {
          id: item.id,
          name: item.name,
          image: item.image,
          flag: item.flag,
          is_university: item.is_university,
          is_institute: item.is_institute,
        };
      });

      const list = res.data.data.filter(item => item.is_institute);
      state.destinationsListEnglish = list.map(item => {
        return {
          id: item.id,
          name: item.name,
          image: item.image,
          flag: item.flag,
          is_university: item.is_university,
          is_institute: item.is_institute,
        };
      });

      state.destinationsList = res.data.data
        .filter(resItem => Boolean(resItem.iso_code))
        .map(item => {
          const country = countries?.data?.find(c => c.iso_code === item.iso_code);
          if (!country) return null;

          const show = country.flag || country.emoji || '';
          return {
            id: item.id,
            show,
            code: item.code ?? '',
            flag: country.flag ?? '',
            name: item.name ?? '',
            image: item.image,
            numberLength: item.number_length ?? 0,
            is_university: item.is_university,
            is_institute: item.is_institute,
          };
        })
        .filter(Boolean); // Remove any null values from the array

      state.loading = true;
    });
  },
};

import api from '@/api';
export const state = () => ({
  universities: [],
  universitiesList: [],
  universitiesListDestination: [],
  getUniversitiesDestinationList: [],
  universitiesListID: [],
  universitiesListByCity: [],
  loading: false,
  result: [],
  keyWord: '',
  query: '',
  order: '',
  per_page: 9,
  mark: '',
});

export const actions = {
  async getUniversities({ state }) {
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api.get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/universities`).then(res => {
      state.universitiesList = res.data.data.map(item => {
        return { id: item.id, name: item.name, query: `&university_ids[]=${item.id}` };
      });
    });
  },

  async getUniversitiesDestination({ state }) {
    let query = '';
    if (state.getUniversitiesDestinationList.length) {
      query = `&${state.getUniversitiesDestinationList}`;
    }
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/universities?per_page=400&sort=1${query}`)
      .then(res => {
        state.universitiesListDestination = res.data.data.map(item => {
          return { id: item.id, name: item.name, query: `&university_ids[]=${item.id}` };
        });
      });
  },

  async getUniversitiesId({ state }, payload) {
    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/university-courses?university_id=${payload}`)
      .then(res => {
        state.universitiesListID = res.data.data;
      });
  },
  async getUniversitiesByCity({ state }, payload) {
    if (!payload) {
      payload = '';
    }

    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .get(`https://${subDomain}.${useRuntimeConfig().public.baseURL}/universities?per_page=400${state.mark}${payload}`)
      .then(res => {
        state.universitiesListByCity = res.data.data.map(item => {
          return { id: item.id, name: item.name, query: `&university_ids[]=${item.id}` };
        });
      });
  },

  async getUniversitiesSearch({ state }, payload) {
    if (payload.keyWord) {
      state.keyWord = payload.keyWord;
    } else {
      state.keyWord = '';
    }
    if (payload.query) {
      state.query = payload.query;
    } else {
      state.query = '';
    }
    state.loading = true;

    const subDomain = useCookie('subagent_userInfo').value.subDomain;
    await api
      .get(
        `https://${subDomain}.${useRuntimeConfig().public.baseURL}/universities?${state.keyWord}&per_page=${state.per_page}${state.query}&${state.order}`
      )
      .then(res => {
        state.loading = false;
        state.data = res.data.data;
        state.result = [
          ...state.data.map(item => ({
            id: item.id,
            type: item.type,
            name: item.name,
            offer: item.fees,
            offerCount: item.sale_percentage + '%',
            price: item.fees_after_sale,
            city: item.city,
            country: item.country,
            image: item.image,
            favourite: item.favourite,
            course_type: item.course_type,
            currency: item.currency,
            logo: item.logo,
            max_fees: item.max_fees,
          })),
        ];
      })
      .catch(() => {
        state.loading = false;
      });
  },
};
